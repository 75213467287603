"use client"

import { useCallback, useState } from "react"
import Image from "next/image"
import { ChevronLeft, ChevronRight } from "lucide-react"

import { cn } from "@/lib/utils"

import { Button } from "./button"

interface ImageSliderProps {
  images: {
    src: string
    alt: string
  }[]
  className?: string
}

export function ImageSlider({ images, className }: ImageSliderProps) {
  const [currentIndex, setCurrentIndex] = useState(0)

  const previousSlide = useCallback(() => {
    setCurrentIndex((current) =>
      current === 0 ? images.length - 1 : current - 1
    )
  }, [images.length])

  const nextSlide = useCallback(() => {
    setCurrentIndex((current) =>
      current === images.length - 1 ? 0 : current + 1
    )
  }, [images.length])

  if (!images.length) return null

  return (
    <div className={cn("group relative h-[400px] w-full", className)}>
      <div className="relative h-full w-full overflow-hidden rounded-lg">
        <Image
          src={images[currentIndex].src}
          alt={images[currentIndex].alt}
          fill
          priority
          className="object-cover transition-transform duration-500"
        />
      </div>

      {/* Navigation Buttons - Only show if more than one image */}
      {images.length > 1 && (
        <>
          <Button
            variant="ghost"
            size="icon"
            className="absolute left-2 top-1/2 -translate-y-1/2 opacity-0 transition-opacity group-hover:opacity-100"
            onClick={previousSlide}
          >
            <ChevronLeft className="h-6 w-6" />
          </Button>

          <Button
            variant="ghost"
            size="icon"
            className="absolute right-2 top-1/2 -translate-y-1/2 opacity-0 transition-opacity group-hover:opacity-100"
            onClick={nextSlide}
          >
            <ChevronRight className="h-6 w-6" />
          </Button>

          {/* Dots Indicator */}
          <div className="absolute bottom-4 left-1/2 flex -translate-x-1/2 space-x-2">
            {images.map((_, index) => (
              <button
                key={index}
                className={cn(
                  "h-2 w-2 rounded-full transition-colors",
                  currentIndex === index ? "bg-white" : "bg-white/50"
                )}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}
