"use client"

import { useEffect, useState } from "react"
import Link from "next/link"
import { useRouter } from "next/navigation"
import { useUser } from "@auth0/nextjs-auth0/client"
import { loadStripe } from "@stripe/stripe-js"
import { CheckCircle2, MonitorIcon, SmartphoneIcon } from "lucide-react"

import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"

import { useConfig } from "./config-component"

const imageCache: { [key: string]: string } = {}

interface MarketingFeature {
  name: string
}

interface ProductCardProps {
  key: string | number
  product: {
    id: string | number
    name: string
    description: string
    images: string[]
    marketing_features?: MarketingFeature[]
    metadata: {
      category?: string
      agents_available?: string
      sites?: string
      current_agent_actions?: string
      setup_price_id?: string
    }
    prices: {
      id: string
      currency: string
      unit_amount: number
      recurring: { interval: string }
    }[]
  }
  productUrl: string
}

export default function ProductCard({ product, productUrl }: ProductCardProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY } = useConfig()
  const [imageSrc, setImageSrc] = useState<string | null>(null)
  const [productDetailUrl, setProductDetailUrl] = useState<string | null>(null)
  const { user, isLoading: isUserLoading } = useUser()
  const router = useRouter()

  useEffect(() => {
    const imageUrl = product.images[0]
    const productDetailUrl = productUrl
    if (imageUrl) {
      if (imageCache[imageUrl]) {
        setImageSrc(imageCache[imageUrl])
      } else {
        const img = new Image()
        img.src = imageUrl
        img.onload = () => {
          imageCache[imageUrl] = imageUrl
          setImageSrc(imageUrl)
        }
      }
    }
    if (productDetailUrl) {
      setProductDetailUrl(productDetailUrl)
    } else {
      setProductDetailUrl(`/marketplace/${product.id}`)
    }
  }, [product.images, productUrl])

  const handleSubscribe = async () => {
    const checkoutInfo = {
      priceId: product.prices[0].id,
      productId: String(product.id),
      timestamp: new Date().getTime(),
      returnTo: encodeURIComponent(window.location.pathname),
      setupPriceId: product.metadata.setup_price_id
        ? product.metadata.setup_price_id
        : "",
    }
    if (!user && !isUserLoading) {
      // Store in localStorage for redundancy
      localStorage.setItem("pendingCheckout", JSON.stringify(checkoutInfo))
      // User is not logged in, redirect to login with return URL
      const returnTo = encodeURIComponent(window.location.pathname)
      router.push(`/join?returnTo=${returnTo}`)
      return
    }
    // User is logged in, proceed with checkout
    await processCheckout(checkoutInfo)
  }
  const processCheckout = async (checkoutInfo: {
    priceId: string
    productId: string
    setupPriceId?: string
  }) => {
    setIsLoading(true)
    try {
      const response = await fetch("/api/stripe/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId: checkoutInfo.priceId,
          productId: checkoutInfo.productId,
          setupPriceId: checkoutInfo.setupPriceId,
        }),
      })

      if (!response.ok) {
        throw new Error("Network response was not ok")
      }

      const { sessionId } = await response.json()

      // Clear stored checkout info
      localStorage.removeItem("pendingCheckout")

      const stripe = await loadStripe(NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!)
      if (stripe) {
        const { error } = await stripe.redirectToCheckout({ sessionId })
        if (error) {
          console.error("Stripe Checkout Error:", error)
        }
      } else {
        console.error("Failed to load Stripe")
      }
    } catch (error) {
      console.error("Error:", error)
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false)
    }
  }
  // Check for pending checkout on component mount
  useEffect(() => {
    // Only check if we haven't checked before and user is loaded
    if (user && !isUserLoading) {
      const pendingCheckout = localStorage.getItem("pendingCheckout")
      if (pendingCheckout) {
        try {
          const checkoutInfo = JSON.parse(pendingCheckout)
          const timestamp = checkoutInfo.timestamp
          const now = new Date().getTime()

          // Only process if the checkout is less than 1 hour old
          if (now - timestamp < 3600000) {
            processCheckout(checkoutInfo)
          } else {
            // Clear expired checkout info
            localStorage.removeItem("pendingCheckout")
          }
        } catch (error) {
          console.error("Error processing pending checkout:", error)
          localStorage.removeItem("pendingCheckout")
        }
      }
    }
  }, [user, isUserLoading])
  return (
    <Card className="flex h-full flex-col overflow-hidden p-6">
      <CardHeader className="p-0">
        <div className="flex h-24 items-center justify-center p-0">
          {imageSrc ? (
            /* eslint-disable-next-line @next/next/no-img-element */
            <img
              src={imageSrc}
              alt={product.name}
              className="max-h-12 max-w-full object-contain"
            />
          ) : (
            <div className="h-12 w-full" />
          )}
        </div>
      </CardHeader>
      <CardContent className="flex-grow pb-6 pt-3">
        <h2 className="mb-2 text-2xl font-bold">{product.name}</h2>
        <p className="mb-4 text-gray-600">{product.description}</p>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <ul className="space-y-2 text-sm">
              {Array.isArray(product.marketing_features) &&
                product.marketing_features.map((feature, index) => (
                  <li key={index} className="flex items-center text-sm">
                    <CheckCircle2 className="mr-2 h-4 w-4 flex-shrink-0 text-primary" />
                    <span>{feature.name}</span>
                  </li>
                ))}
            </ul>
          </div>
          {/* <div className="flex items-center space-x-2">
            <span className="text-sm font-medium">Agents Available:</span>
            <span className="text-sm bg-gray-100 px-2 py-1 rounded">
              {product.metadata.agents_available || "N/A"}
            </span>
            <span className="text-sm text-gray-500">Sites: {product.metadata.sites || "N/A"}</span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-sm font-medium">Current Agent Actions:</span>
            <span className="text-sm bg-gray-100 px-2 py-1 rounded">
              {product.metadata.current_agent_actions || "N/A"}
            </span>
          </div> */}
        </div>
      </CardContent>
      <CardFooter className="flex items-center justify-between">
        <div className="flex w-full items-center justify-between">
          <div className="flex space-x-2">
            <Button
              className="bg-blue-600 text-white"
              onClick={handleSubscribe}
              disabled={isLoading || isUserLoading}
            >
              {isLoading ? "Processing..." : "Subscribe"}
            </Button>
            <Link
              href={`${productDetailUrl}?returnUrl=${encodeURIComponent(window.location.pathname)}`}
            >
              <Button variant="outline">Details</Button>
            </Link>
          </div>
          <div className="hidden space-x-2">
            <MonitorIcon className="text-gray-400" />
            <SmartphoneIcon className="text-gray-400" />
          </div>
        </div>
      </CardFooter>
    </Card>
  )
}
